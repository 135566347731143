import * as React from 'react';
import {
  Text,
  Heading,
  Box,
  Stack,
  GridItem,
  Grid,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../layout';
import Head from '../components/head';

const About: React.FC = () => (
  <Layout headerColorScheme="light">
    <Head title="About Jamestown" />

    <Box
      padding="4rem calc(var(--cp) + 1rem) 3rem"
      position="relative"
      align="center"
      color="#fff"
    >
      <VStack width="500px" maxWidth="100%" mt={5} spacing={5} color="black">
        <Heading size="2xl" color="purple" align="center">
          About Jamestown
        </Heading>
        <Text fontWeight={500}>
          Your next transaction with Jamestown is not a transaction, it is the
          Jamestown experience. Transact different!
        </Text>
      </VStack>
    </Box>

    <Box
      margin={{
        md: '2rem calc(var(--cp) + 0.2rem) 7rem',
        sm: '2rem calc(var(--cp) + 0.2rem) 8rem',
        base: '2rem calc(var(--cp) + 0.2rem) 9rem',
      }}
      position="relative"
      sx={{
        '.gatsby-image-wrapper': {
          borderRadius: 'xl',
        },
      }}
    >
      <StaticImage src="../images/about-hero.jpg" alt="About Jamestown" />

      <Box
        position="absolute"
        color="white"
        bg="purple"
        p={5}
        borderRadius="0"
        borderBottomLeftRadius="xl"
        borderBottomRightRadius="xl"
        bottom={{ md: -10, sm: -20, base: -40 }}
        width="600px"
        maxW="80%"
      >
        <Text>
          The mission of Jamestown is to drive service innovation for the
          ever-increasing E-commerce market all over the world. Countless ideas
          are being tested and implemented daily to serve our customers better,
          transact conveniently and deliver nothing less of the best to all our
          patrons world wide.
        </Text>
      </Box>
    </Box>

    <Grid
      templateColumns={{ md: '1fr 1fr', base: '1fr' }}
      padding="3rem calc(var(--cp) + 1rem)"
      gap={10}
    >
      <GridItem>
        <Heading size="xl" color="purple">
          We are one of the fastest emerging E-commerce platforms in the
          Nigeria.
        </Heading>
      </GridItem>

      <GridItem>
        <Stack spacing={5}>
          <Text>
            We are a team of skilled personnel; our personality is interactive
            and cheerful. Our work is geared towards offering a fresh and
            energetic approach. We work continuously to deliver a safe exchange
            environment that allows you trade your digital assets with ease;
            conduct a seamless e-commerce enterprise and introduce you to a
            world of easy access to luxury. We take pride in our one-on-one
            attentive relationships, in which every step is given care. That is
            Jamestown, we make everyday transactions hassle free. Let Jamestown
            handle it!
          </Text>

          <Text>
            We have quickly peaked to being one the most reliable platforms for
            our customer&apos;s day to day deals especially with our 24/7 policy
            that allows for non- stop transaction all hours of the day. Here at
            JamestownNg, our principles are built on convenience, integrity,
            modernity, timeliness and customer satisfaction. Definitely, we are
            your best bet and your go to for anything E- commerce especially if
            stress-free is your watchword. Sit back and relax! Allow us be a
            part of your success story today as we lessen your burden and make
            smooth as well as effortless the handling of your business deals.
          </Text>
        </Stack>
      </GridItem>
    </Grid>

    <Box align="center" m="5rem calc(var(--cp) + 1rem)">
      <VStack width="450px" maxWidth="100%" textAlign="center">
        <Heading color="purple">Meet our team</Heading>
        <Text>
          With lots of unique blocks, you can easily build a page without
          coding. Build your next consultancy website within few minutes.
        </Text>
      </VStack>

      <SimpleGrid
        m="3.5rem 0"
        spacing={10}
        minChildWidth={{ lg: '250px', base: '180px' }}
        sx={{
          '.gatsby-image-wrapper': {
            borderRadius: 5,
            mb: 5,
          },
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box align="center" key={index + 1}>
            <StaticImage
              src="../images/team/james.ochuko.webp"
              alt="James Ochuko"
            />

            <VStack>
              <Heading size="md">James Ochuko</Heading>
              <Text fontSize="sm">Founder & CEO</Text>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  </Layout>
);

export default About;
